<head><meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></head>
<template>
    <div class="home-container">
        <div class="welcome-text">
            {{ welcomeMessage }}

        </div>
        <div class="messages-block block">
            <div class="message-count">
                <div class="blue-circle">{{ this.response_count }}</div>
            </div>
                <div class="text">{{ messagesLeftText }}</div>

        </div>
        
        <div v-if="subscription.rate_status === true" class="subscription-block">
            <div class="subscription-status">{{ subscription.by_rate }}</div>
            <div class="subscription-expiration">До {{ subscription.end_date }}</div>
            
        </div>

        <div v-if="coupon|| subscription.by_rate === 'PLATINUM'" class="mode-selectors">
            <div class="toggle-container">
                <input @click="handleModeSelection('gpt')" type="radio" id="toggle1" class="toggle-input" name="toggle-group" :checked="isCurrentMode('gpt')">
                <label for="toggle1" class="toggle-label">
                    <div class="toggle-slider"></div>
                </label>
                <span>Вопрос-ответ</span>
            </div>

            <div class="toggle-container">
                <input @click="handleModeSelection('gpt_pars')" type="radio" id="toggle2" class="toggle-input" name="toggle-group" :checked="isCurrentMode('gpt_pars')">
                <label for="toggle2" class="toggle-label">
                    <div class="toggle-slider"></div>
                </label>
                <span>Поиск в интернете</span>
            </div>

            <div class="toggle-container">
                <input @click="handleModeSelection('dell_e')" type="radio" id="toggle3" class="toggle-input" name="toggle-group" :checked="isCurrentMode('dell_e')">
                <label for="toggle3" class="toggle-label">
                    <div class="toggle-slider"></div>
                </label>
                <span>Создание изображений</span>
            </div>

            <div class="toggle-container">
                <input @click="handleModeSelection('code')" type="radio" id="toggle4" class="toggle-input" name="toggle-group" :checked="isCurrentMode('code')">
                <label for="toggle4" class="toggle-label">
                    <div class="toggle-slider"></div>
                </label>
                <span>Написание кода</span>
            </div>

        </div>

        <div class="subscriptions-section">
            <div v-for="subscription in subscriptions" :key="subscription.name" class="subscription-card">
                <div class="subscription-price">{{ subscription.name }} {{ subscription.price }} ₽</div>
                <div class="subscription-description">{{ subscription.description }}</div>
                <a :href="subscription.payment_link" @click="closeApp" class="blue-button">{{ pay_button }}</a>
            </div>
        </div>

        <footer>
          <h3 class="footer-text">Made by Fulla Technology❤️</h3>  
        </footer>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            welcomeMessage: "Привет",
            messageCount: 0,
            hasSubscription: false,
            subscription: {},
            messagesLeftText: "Сообщений осталось",
            subscriptions: [
                {   
                    name: "🏆PLATINUM ",
                    price: "2 100",
                    description: "🧠+Поддержка расширенного искусственного интеллекта GPT-4o\n" +
                        "⚠️+Расширенная тех.поддержка\n" +
                        "⚡️+Доступ к новым возможностям\n" +
                        "🗣+Задавай вопрос голосом",
                    payment_link: "https://t.me/$gIEB2mt08UkVCAAA6mzsISlcYgI",
                    
                },
                {
                    name: "💈ELITE",
                    price: "1 100",
                    description: "🧠 GPT-4o mini\n" +
                        "💬 Неограниченное общение 30 дней \n" +
                        "📣Тех.поддержка",
                    payment_link: "https://t.me/$N_qxoWt08UkUCAAAEK0E9DH3yK4",
                },
            ],

            serverAddress: "https://eruditechat.ru/", // Replace with your server address
            language: "ru",
            pay_button: "Оплатить",
            tg_user_id: 0,
            first_name: "",
            currentMode: "",
        };
    },
    mounted() {
        this.fetchUser();
        this.initWebApp();

    },
    methods: {
        async fetchUser() {
            try {
                let head = document.getElementsByTagName('head')[0];
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'https://telegram.org/js/telegram-web-app.js';
                await head.appendChild(script);
                while(!window.Telegram?.WebApp?.initDataUnsafe?.user?.id){
                    await new Promise(r => setTimeout(r, 500));
                    if(window.Telegram?.WebApp?.initDataUnsafe?.user?.id){
                        break;
                    }
                }
                this.tg_user_id = window.Telegram?.WebApp?.initDataUnsafe?.user?.id ||0;
                this.first_name = window.Telegram?.WebApp?.initDataUnsafe?.user?.first_name || window.Telegram?.WebApp?.initDataUnsafe?.user?.username || "";

                const response = await axios.get(
                    `${this.serverAddress}/api/v1/user/data/${this.tg_user_id}/`);
                this.language = response.data.language;
                this.email = response.data.email;
                this.registration_date = response.data.registration_date;
                this.user_rate = response.data.user_rate;
                switch(this.language){
                    case 'ru':
                        this.messagesLeftText = "Сообщений осталось"
                        this.welcomeMessage = "Привет, " + this.first_name + "!"
                        this.pay_button = "Оплатить"
                        break;
                    default:
                        this.messagesLeftText = "Messages left"
                        this.welcomeMessage = "Hi, " + this.first_name + "!"
                        this.pay_button = "Pay"
                        break;
                }
                this.coupon = response.data.coupon;
                // this.couponStatus = response.data.coupon.status;
		console.log(response.data.response_count);
		console.log(response.data.rate_status);
		this.response_count = response.data.response_limit - response.data.response_count;
        if (this.response_count < 0){
            this.response_count = 0;
        } 
                this.subscription.by_rate = response.data.user_rate.by_rate;
                if (this.subscription.by_rate) {
                    this.hasSubscription = true;
                }
                this.currentMode = response.data.usermode.mode;
                this.subscription.rate_status = response.data.user_rate.rate_status;
                this.subscription.start_date = response.data.user_rate.start_date.split("T")[0].replaceAll("-", "/");
                this.subscription.end_date = response.data.user_rate.end_date.split("T")[0].replaceAll("-", "/");
            } catch (error) {
                console.error(error);
            }
        },
        async initWebApp() {
            try {
                await this.fetchUser();
            } catch (error) {
                console.error("Error fetching subscriptions", error);
            }
        },
        async updateUserMode(mode) {
            try {
                const response = await axios.put(
                    `${this.serverAddress}/api/v1/user/update-mode/`,
                    {
                        'user_id': this.tg_user_id,
                        'mode': mode,},
                    {
                headers: {
                    'Content-Type': 'application/json',
            },
    }
    );
            console.log('Mode updated successfully:', response.data);
            // this.currentMode = mode;

            // this.someState = response.data.someValue;
            } catch (error) {
                console.error('Error updating user mode:', error);
            }
        },

        async handleModeSelection(selectedMode) {
            console.log('Selected Mode:', selectedMode);
            await this.updateUserMode(selectedMode);
            // this.someState = 'Updated state';
        },

         isCurrentMode(mode) {
            return this.currentMode === mode;
        },

        async closeApp() {
            window.Telegram?.WebApp?.close();
        }

    },
};

</script>


<style>
/* General styles */
.home-container {
    width: 98%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0.75em;
    padding-top: 2em;
    color: #17212b;
    /* Dark gray text color */
    display: flex;
    flex-direction: column;
    align-items: left;
}

.text {
    font-size: 15px;
}

.block {
    background-color: white;
    /* White background */
    padding: 1em;
    border-radius: 1.5em;
    margin-bottom: 1.5em;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow */
}

/* Welcome section */
.welcome-text {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Messages section */
.messages-block {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 20px;
    flex-direction: row;
}

.message-count {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #2196f3;
    /* Blue color */
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

/* Subscription section */
.subscription-block {
    background-color: white;
    /* White background */
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow */
    color: #17212b;
    /* Dark gray text color */
    white-space: pre-wrap;
}

.subscription-status {
    font-size: 16px;
    font-weight: bold;
}

.subscription-expiration {
    font-size: 15px;
    margin-top: 8px;
    white-space: pre-wrap;
    font-family: "Courier New", Courier, monospace;
}

/* Subscriptions section */
.subscriptions-section {
    display: flex;
    flex-direction: column;
}

.subscription-card {
    background-color: white;
    /* White background */
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    margin-bottom: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow */
    color: #17212b;
    /* Dark gray text color */
    flex-direction: column;
}

.subscription-price {
    font-size: 20px;
    font-weight: bold;
}

.subscription-description {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 16px;
    white-space: pre-wrap;
}

.blue-button {
    background-color: #2196f3;
    /* Blue color */
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    font-weight: bold;
    max-width: fit-content;
}

footer {
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    /* Dark gray background */
    color: #17212b;
    text-align: center;
    padding: 10px;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.footer-text{
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}
/* Стили для тумблера */

.mode-selectors {
    padding: 20px;
}

.toggle-container {
    display: flex;
    align-items: center;
    padding: 4px;
}

.toggle-label {
    margin-right: 1em;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    background-color: #2196f3;
    border-radius: 12px;
    cursor: pointer;
}

.toggle-input {
    display: none;
}

.toggle-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.toggle-input:checked + .toggle-label .toggle-slider {
    transform: translateX(26px);
    background-color: #0012d3;
}
/* Media queries for responsiveness */
@media screen and (max-width: 500px) {
    .messages-block {
        flex-direction: row;
    }

    .subscriptions-section {
        grid-template-columns: 1fr;
    }

}</style>
