<template>
  <HomeMain/>
</template>

<script>

import HomeMain from './components/HomeMain.vue'

export default {
  name: 'App',
  components: {
    HomeMain
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Satoshi-Variable,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
html {
  background-color: lightgray;
}
</style>
